import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import the router
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { createI18n } from 'vue-i18n';
import en from './i18n/en.json';
import es from './i18n/es.json';

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en, es }
});

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

createApp(App)
  .use(router) // Use the router
  .use(i18n)
  .use(vuetify)
  .mount('#app');
